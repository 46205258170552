<template lang="pug">
    .main-wrapper.caixas-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "agencias" }'> Agência Bancária</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } agência bancária`")
                        .p-grid.p-fluid.p-align-end(style='margin: 0 auto; ')

                            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.cd_banco.$error }")
                                label.form-label Banco:
                                Dropdown(v-model='$v.model.cd_banco.$model' :options='options.bancos' 
                                    dataKey='value' optionLabel='nm_banco' optionValue='id' placeholder='Selecione')
                                .feedback--errors(v-if='submitted && $v.model.cd_banco.$error')
                                    .form-message--error(v-if="!$v.model.cd_banco.required") <b>Banco</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_agencia.$error }")
                                label.form-label Nº Agência:
                                InputText(type='text' v-model='$v.model.nr_agencia.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_agencia.$error')
                                    .form-message--error(v-if="!$v.model.nr_agencia.required") <b>Agência</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_telefone.$error }")
                                label.form-label Telefone:
                                InputMask(:mask="'(99) 99999999?9'" v-model='$v.model.nr_telefone.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_telefone.$error')
                                    .form-message--error(v-if="!$v.model.nr_telefone.minLength") <b>Telefone</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nr_telefone.required") <b>Telefone</b> é obrigatório.

                            .p-col-12.p-md-6
                                label.form-label Nome Gerente:
                                InputText(type='text' v-model='model.nm_gerente')
                                    
                            .p-col-12.p-md-3
                                label.form-label WhatsApp Gerente:
                                InputMask(:mask="'(99) 99999999?9'" v-model='model.nr_whatsapp_gerente')
                              
                            .p-col-12.p-md-3
                                label.form-label Email Gerente:
                                InputText(type='text' v-model='model.ds_email_gerente')
                               
                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_bairro.$error }")
                                label.form-label Bairro:
                                InputText(type='text' v-model='$v.model.nm_bairro.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_bairro.$error')
                                    .form-message--error(v-if="!$v.model.nm_bairro.minLength") <b>Bairro</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_bairro.required") <b>Bairro</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nm_rua.$error }")
                                label.form-label Rua:
                                InputText(type='text' v-model='$v.model.nm_rua.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_rua.$error')
                                    .form-message--error(v-if="!$v.model.nm_rua.minLength") <b>Rua</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nm_rua.required") <b>Rua</b> é obrigatório.

                            .p-col-12.p-md-6
                                label.form-label Complemento:
                                InputText(type='text' v-model='model.ds_complemento')

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_cep.$error }")
                                label.form-label CEP:
                                InputMask(mask='99999-999' v-model='$v.model.nr_cep.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_cep.$error')
                                    .form-message--error(v-if="!$v.model.nr_cep.minLength") <b>CEP</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nr_cep.required") <b>CEP</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.nr_estabelecimento.$error }")
                                label.form-label Número do estabelecimento:
                                InputText(type='text' v-model='$v.model.nr_estabelecimento.$model')
                                .feedback--errors(v-if='submitted && $v.model.nr_estabelecimento.$error')
                                    .form-message--error(v-if="!$v.model.nr_estabelecimento.minLength") <b>Número do Estabelecimento</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.nr_estabelecimento.required") <b>Número do Estabelecimento</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.cd_estado.$error }")
                                label.form-label Estado *:
                                Dropdown(v-model='$v.model.cd_estado.$model' :options='options.estados' @change='getCidades()'
                                    optionLabel='textAlt' optionValue='value' placeholder='Selecione' filter)
                                .feedback--errors(v-if='submitted && $v.model.cd_estado.$error')
                                    .form-message--error(v-if="!$v.model.cd_estado.required") <b>Estado</b> é obrigatório.

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.cd_cidade.$error }")
                                label.form-label Município *:
                                Dropdown(v-model='$v.model.cd_cidade.$model' :options='options.cidades'
                                    optionLabel='text' optionValue='value' placeholder='Selecione' filter)
                                .feedback--errors(v-if='submitted && $v.model.cd_cidade.$error')
                                    .form-message--error(v-if="!$v.model.cd_cidade.required") <b>Município</b> é obrigatório.

                            .p-col-12.mt-2(style='display: flex; justify-content: center; align-items: center')
                                Button(label='Salvar' type="submit" style='max-width:20%')

                
</template>

<style lang="scss">
    .caixas-salvar {
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'
    import InputSwitch from "primevue/inputswitch";
    import Textarea from 'primevue/textarea'
    import CurrencyInput from "@/components/CustomComponents/CurrencyInput";
    import { Agencia, Bancos, DominioValor, Utils } from './../../middleware'
    import { required } from 'vuelidate/lib/validators'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password,
                InputMask, SelectButton, Dropdown, Tooltip, InputSwitch, Textarea, CurrencyInput
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true
            this.options.bancos.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -' })
            Bancos.findAll().then(response => {
                if (response.status == 200) {
                    this.options.bancos = response.data
                }
            })
            Utils.getUFs().then(response => {
                if(([200,201]).includes(response.status)){
                    this.options.estados = response.data.map(item => ({ value: item.id, text: item.ds_sigla, textAlt: item.nm_estado }))
                }
            })
            DominioValor.findAll({ds_mnemonico: 'TIPO_CONTA_BANCARIA'}).then((response) => {
					if(response.status === 200) {
						response.data['TIPO_CONTA_BANCARIA'].valores.forEach(item => {
							this.options.tipo_conta.push({value: item.ie_valor, label: item.ds_valor})
						})
					}
				})
            let id = 0
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id != 0) {
                    Agencia.find(id).then(response => {
                    if (([200,201]).includes(response.status)) {
                       this.model = response.data
                       this.model.nr_telefone = response.data.nr_telefone.substring(3)
                       this.getCidades(false)
                    }
                    this.waiting = false
                })
            } else this.waiting = false
            
        },
        data () {
            return {
                model: {
                    nr_agencia: null,
                    nm_bairro: null,
                    nm_rua: null,
                    nr_cep: null,
                    nr_estabelecimento: null,
                    nr_telefone: null,
                    nm_gerente: null,
                    ds_email_gerente: null,
                    nr_whatsapp_gerente: null,
                    cd_cidade: null,
                    cd_banco: null,
                    cd_estado: null
                },
                options: {
                    bancos: [],
                    tipo_conta: [],
                    agencias: [],
                    estados: [],
                    cidades: []
                },
                waiting: true,
                waitingEstado: false,
                waitingCidade: false,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    nr_agencia: { required },
                    cd_banco: { required },
                    nm_bairro: { required },
                    nm_rua: { required },
                    nr_cep: { required },
                    nr_estabelecimento: { required },
                    nr_telefone: { required },
                    cd_cidade: { required },
                    cd_estado: { required }
                }
            }
            return v
        },
        watch: {
            'model.cd_estado': function() {
                this.waitingCidade = true
                this.getCidades()
            },
            'model.cd_banco': function (val) {
                this.options.agencias = []
                Agencia.findAll({cd_banco: val}).then(response => {
                    if(response.status == 200) {
                        response.data.forEach(item => {
                            this.options.agencias.push({
                                label: 'Agência ' + item.nr_agencia +  ' - ' + item.nm_banco,
                                value: item.id,
                            })
                        })
                    }
                    console.log(response)
                })
            },
        },
        methods: {
            getCidades(cidade = false) {
                if(cidade) this.model.cd_cidade = null
                if(this.model.cd_estado !== null) {
                    Utils.getMunicipiosEstadoId(this.model.cd_estado).then(response => {
                        this.waitingCidade = false
                        if(([200,201]).includes(response.status)){
                            this.options.cidades = response.data.map(item => ({ value: item.id, text: item.nm_cidade }))
                        }
                    })
                }
            },
            handleSubmit () {
                this.submitted = true
                
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)
                delete dataSend.cd_estado
                this.waitingForm = true
                
                Agencia.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'agencias' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
                
                
            }
         }
      }
</script>